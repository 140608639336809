import { Component, ComponentType, ErrorInfo, ReactNode } from 'react'
import { BiError } from 'react-icons/bi'
import { Box, Text } from '@chakra-ui/layout'
import { API } from '@kleo/types'

import { useEventLogger } from '../hooks/useEventLogger'
import { I18Context } from '../providers/i18Provider'
import { ReactError } from '../utils/appError'

type errorBoundaryState = {
  hasError: boolean
}

// ErrorBoundaryProps now extends WithEventLoggerHOCProps and includes children
type ErrorBoundaryProps = {
  children: ReactNode
} & WithEventLoggerHOCProps

type WithEventLoggerHOCProps = {
  logUIErrorEvent: (payload: API.LogUIEventErrorPayload) => void
}

class ErrorBoundary extends Component<ErrorBoundaryProps, errorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(_error: Error, errorInfo: ErrorInfo): void {
    this.props.logUIErrorEvent({
      errorMessage: 'ui-run-time-error',
      error: new ReactError('React UI error', errorInfo),
    })
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <I18Context.Consumer>
          {(context) => (
            <Box className="flex flex-col items-center flex-grow mt-20 mb-40 text-center width-layout">
              <Box className="flex items-center mb-4">
                <BiError className="mr-2 text-2xl text-red-600 md:text-3xl" />
                <Text as="h1" className="text-xl md:text-2xl font-opensanscondensed">
                  {context.t('generic.errorBoundary1')}
                </Text>
              </Box>
              <Text as="h2" className="md:text-lg">
                {context.t('generic.errorBoundary2')}
              </Text>
            </Box>
          )}
        </I18Context.Consumer>
      )
    }

    return this.props.children
  }
}

const withEventLoggerHOC = <P extends WithEventLoggerHOCProps>(Component: ComponentType<P>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (props: any) => {
    const { logUIErrorEvent } = useEventLogger()

    return <Component logUIErrorEvent={logUIErrorEvent} {...props} />
  }
}
// Wrap ErrorBoundary with HOC and forward props
export const ErrorBoundaryWithLogger = withEventLoggerHOC(ErrorBoundary)
