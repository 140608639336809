import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@chakra-ui/hooks'
import { API, ConversationConfigUpdateResponse, ReadDocumentAPIResponse } from '@kleo/types'
import { TFunction } from 'i18next'
import mime from 'mime'

import { useEventLogger } from 'hooks/useEventLogger'

import { AzureFileUploadError, EmptyError, NetworkError, UploadError, UploadSchemaError } from 'utils/appError'
import { getFileExt, getMimeType, isBinaryFile } from 'utils/fileUtils'
import { formatMaxFileSize } from 'utils/formatter'
import { generateRequestId } from 'utils/generateRequestId'
import { fetchData, putFileIntoStorage } from 'utils/http/methods'
import { replaceIdInRecordBotSpecific } from 'utils/replaceIdInRecord'

import type { FEUIConfig, FileConfigs } from 'types/types'

import { useAuthContext } from './AuthProvider'
import { useConfigContext } from './ConfigurationProvider'
import { useI18Context } from './i18Provider'

type UploadContextType = {
  deleteDocumentContent: (
    botNames: string[],
    conversationIDsToDelete: string[],
    saveConversation?: boolean
  ) => Promise<void>
  deleteTempDocuments: (botNames: string[]) => void
  docContents: Record<string, Record<string, UploadDocContents>>
  isDeletingFileContext: boolean
  isFileUploading: boolean
  isWarningAcknowledged: boolean
  getUploadFileParameters: (botName: string) => {
    maxFileNameLength: number
    ocrMaxFileLimit: number
    uploadFileMaxCount: number
    uploadFileMaxSize: number
    fileTypesByMaxSize: Record<string, string[]>
  }
  replaceConversationIdInDocContents: (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => void
  setDocContents: Dispatch<SetStateAction<Record<string, Record<string, UploadDocContents>>>>
  setIsWarningAcknowledged: Dispatch<SetStateAction<boolean>>
  setUploadError: Dispatch<SetStateAction<string>>
  tempDocContents: Record<string, UploadDocContents>
  uploadError: string
  uploadFileRequest: (
    botName: string,
    file: File,
    fileConfigs: FileConfigs,
    saveConversations: boolean,
    uploadTokenMaxSize: number,
    kBotUploadedCharacters: number,
    conversationID?: string | null,
    append?: boolean
  ) => void
  uploadModalOperations: {
    isOpen: boolean
    onOpen: () => void
    onClose: () => void
  }
}

export const UploadContext = createContext<UploadContextType>({} as UploadContextType)

type UploadProviderProps = {
  children?: React.ReactNode
  config: FEUIConfig[] | undefined
}

type UploadDocContents = {
  text: string
  documentId: string
  characterCount: number
  size: number
  type: string
  usedOCR: boolean
}

export const UploadProvider = ({ children, config }: UploadProviderProps) => {
  const { getToken } = useAuthContext()
  const { API_ENDPOINT } = useConfigContext()
  const { logUIErrorEvent } = useEventLogger()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation('uploadModal')
  const { language } = useI18Context()

  const [isFileUploading, setIsFileUploading] = useState(false)
  const [docContents, setDocContents] = useState<Record<string, Record<string, UploadDocContents>>>({})

  const [tempDocContents, setTempDocContents] = useState<Record<string, UploadDocContents>>({})

  const [uploadError, setUploadError] = useState('')
  const [isWarningAcknowledged, setIsWarningAcknowledged] = useState<boolean>(false)

  const [isDeletingFileContext, setIsDeletingFileContext] = useState<boolean>(false)

  const isDeletingFileContextRef = useRef(false)

  // Need to wrap this in a useMemo, otherwise calling "new Intl.NumberFormat" consecutively causes the site to lag
  const numberFormat = useMemo(() => {
    return new Intl.NumberFormat(language)
  }, [language])

  const getUploadFileParameters = useCallback(
    (botName: string) => {
      const uploadBotConfig = config?.find(
        (botConfig) => botConfig.botName.toLowerCase() === botName.toLocaleLowerCase()
      )

      const fileTypesByMaxSize: { [key: string]: string[] } = {}
      const allowedTypes = uploadBotConfig?.allowedUploadTypes
      if (allowedTypes) {
        for (const [fileType, fileTypeLimits] of Object.entries(allowedTypes)) {
          const { size } = fileTypeLimits
          if (size) {
            const formattedSize = formatMaxFileSize(size, numberFormat, t as TFunction)
            if (fileTypesByMaxSize[formattedSize]) {
              fileTypesByMaxSize[formattedSize].push(fileType)
            } else {
              fileTypesByMaxSize[formattedSize] = [fileType]
            }
          }
        }
      }
      return {
        fileTypesByMaxSize,
        maxFileNameLength: uploadBotConfig?.fileNameLength ?? 0,
        ocrMaxFileLimit: uploadBotConfig?.ocrMaxFileLimit ?? 0,
        uploadFileMaxCount: uploadBotConfig?.uploadFileMaxCount ?? 0,
        uploadFileMaxSize: uploadBotConfig?.uploadFileMaxSize ?? 0,
      }
    },
    [config, numberFormat, t]
  )

  const mapFileToText = (file: File, fileConfigs: FileConfigs) => {
    const fileNameSplits = file.name.split('.')
    const fileExt = fileNameSplits[fileNameSplits.length - 1]
    // Need to set the type to "text/plain" to those files that can't be translated using the "mime" library
    if (fileConfigs.mapUploadTypesToText.includes(fileExt)) {
      // Create a new instance of the File type, specifically with type equaling "text/plain"
      // Need to create a new instance since the original file object is read-only
      const fileTemp = file.slice(0, file.size, file.type)
      return new File([fileTemp], file.name, {
        type: 'text/plain',
        lastModified: file.lastModified,
      })
    }

    return
  }

  const verifyAcceptableFile = async (file: File, fileConfigs: FileConfigs) => {
    const ext = getFileExt(file.type)
    const fileNameSplits = file.name.split('.')
    const fileExt = fileNameSplits[fileNameSplits.length - 1]
    const extensionTypes = [...new Set([...fileConfigs.allowedUploadTypes, ...fileConfigs.mapUploadTypesToText])]

    if ((ext && extensionTypes.includes(ext)) || extensionTypes.includes(fileExt)) {
      // .ts file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file

      // ".ts" file (typescript) will be recognized as video/mp2t, so we need to identify the content of the file
      if (ext === 'ts') {
        const isBinary = isBinaryFile(await file.slice(0, file.size, file.type).arrayBuffer())
        // isBinary tells us if the file is a video of mime-type "video/mp2t"
        if (isBinary) {
          return
        }
      }

      return file
    }
    return
  }

  const getUploadUrl = async ({ file, contentType }: { file: File; contentType: string }, botName: string) => {
    try {
      const token = await getToken()

      const response = await fetchData<API.UploadAPI.GetUrlUploadRes>({
        url: `${API_ENDPOINT}/uploadapi/${botName === 'KBOTS' ? 'urlUpload' : 'urlBotUpload'}`,
        token,
        payload: {
          contentType,
          botName: botName === 'KBOTS' ? undefined : botName,
          fileName: file.name,
          fileSize: file.size,
          language: 'EN',
        },
        requestId: generateRequestId(),
      })

      // if uploadurl is a success, because documentId only exist in success response
      if ('documentId' in response) {
        return { documentId: response.documentId, url: response.url }
      }
      // schema error
      throw new UploadSchemaError(response.errorList?.[0].code)
    } catch (e) {
      if (e instanceof UploadSchemaError) {
        throw new UploadSchemaError(e.message)
      }

      throw new UploadError('upload-request-error-uploadUrl', e as Error)
    }
  }

  const putFileIntoStorageContainer = async ({
    append,
    botName,
    botNameForAPIToUse,
    conversationId,
    documentId,
    file,
    contentType,
    kBotUploadedCharacters,
    saveConversations,
    uploadTokenMaxSize,
    url,
  }: {
    append: boolean
    botName: string
    botNameForAPIToUse: string
    conversationId?: string | null
    documentId: string
    file: File
    contentType: string
    kBotUploadedCharacters: number
    saveConversations: boolean
    uploadTokenMaxSize: number
    url: string
  }) => {
    // We need to always include "?." here in case the botName/conversationId being passed through can't be mapped to an item in either docContents or tempDocContents
    // This is especially for the case where in K-Bots we always pass in a conversationId, such as "createKBotTemp" or "editKBotTemp"
    const docContentsToUse = conversationId ? docContents?.[botName]?.[conversationId] : tempDocContents?.[botName]

    try {
      await putFileIntoStorage({
        url,
        file,
        headers: {
          'Content-Type': contentType,
          'x-ms-blob-type': 'BlockBlob',
        },
        feRequestId: generateRequestId(),
      })

      const extensionComponents = file.name.split('.')
      if (extensionComponents.length === 0) {
        throw new UploadError('upload-request-error-no-file-extension')
      }
      const fileExtension = `.${extensionComponents.pop()}`.toLowerCase()
      const { text, characterCount, usedOCR } = await getDocumentText({
        botName: botNameForAPIToUse,
        documentId,
        fileExtension,
      })

      // Make sure that we sit below the max character count for a newly uploaded file, taking into consideration K-Bot fileContent and previously uploaded files when applicable
      const totalCharacterLimit =
        uploadTokenMaxSize * 4 - // the base character limit
        kBotUploadedCharacters // character count of uploaded files for a kbot's knowledge base

      const docContentsToAppend = append ? docContentsToUse : undefined

      // If we are going to show as HTML, we need to sanitize the text here so that if appending, we don't throw away any text that comes after/within dirty HTML.
      const newDocContentText = `${docContentsToAppend ? `${docContentsToAppend.text}\n\n` : ''}<b>Source</b>: ${file.name}\n\n${text}`

      const usedCharacters = docContentsToUse?.characterCount || 0
      // consider whether the amount we uploaded exceeds the total - used, or if after appending, the character count exceeds the total allowed.
      const exceedsCharacterLimit =
        characterCount > totalCharacterLimit - usedCharacters || newDocContentText.length > totalCharacterLimit

      if (characterCount === 0) {
        throw new EmptyError()
      } else if (exceedsCharacterLimit) {
        throw new UploadError('upload-request-error-exceedsCharLimit')
      }
      // if document id sent is the same as the one in the response, we can continue on

      const fileType = mime.getExtension(file.type) || contentType
      const isImage = ['png', 'jpg', 'jpeg', 'gif'].includes(fileType.toLocaleLowerCase())

      if (conversationId) {
        setDocContents((currentDocContents) => {
          return {
            ...currentDocContents,
            [botName]: {
              ...currentDocContents[botName],
              [conversationId]: {
                text: newDocContentText,
                title: docContentsToAppend ? t('modal.multipleFilesUploaded') : file.name,
                type: fileType,
                documentId,
                characterCount: newDocContentText.length,
                usedOCR: usedOCR && !isImage,
                size: file.size,
              },
            },
          }
        })
      } else {
        setTempDocContents((currentTempDocContents) => {
          return {
            ...currentTempDocContents,
            [botName]: {
              ...currentTempDocContents[botName],
              text: newDocContentText,
              title: docContentsToAppend ? t('modal.multipleFilesUploaded') : file.name,
              type: fileType,
              documentId,
              characterCount: newDocContentText.length,
              usedOCR: usedOCR && !isImage,
              size: file.size,
            },
          }
        })
        // If we haven't created an entry in doc contents for this bot, we need to create an entry now, otherwise when we transfer
        // the docs over from the temporary record, it won't find the bot and it will fail.
        if (!(botName in docContents)) {
          setDocContents((current) => {
            return {
              ...current,
              [botName]: {},
            }
          })
        }
      }

      if (saveConversations && conversationId) {
        try {
          const authToken = await getToken()
          const response = await fetchData<ConversationConfigUpdateResponse>({
            url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
            token: authToken,
            setRetrying: () => false,
            payload: {
              botName,
              convID: conversationId,
              config: {
                fileContext: newDocContentText,
              },
              language: 'EN',
            },
            requestId: generateRequestId(),
          })

          if ('errorList' in response) {
            console.error('Error occurred:', response.errorList[0].code)
          }
        } catch (error) {
          console.error('Error occurred when trying to update file context', error)
        }
      }

      return
    } catch (e) {
      if (e instanceof AzureFileUploadError) {
        logUIErrorEvent({
          bot: botName,
          errorMessage: `Did NOT get back status === 201 && statusText === 'Created' when calling putFileIntoStorageContainer. status: ${e.status}, statusText: ${e.statusText}`,
          requestId: e.requestId,
        })
      }
      if (e instanceof UploadError || e instanceof EmptyError) {
        throw e
      }

      throw new UploadError('upload-request-error-documentUpload', e as Error)
    }
  }

  const getDocumentText = async ({
    botName,
    documentId,
    fileExtension,
  }: {
    botName: string
    documentId: string
    fileExtension: string
  }) => {
    try {
      // Set the timeout of this request based on the type of file that was uploaded, or default if it is not configured. These values are set in the config.
      const botConfig = config?.find((botConfig) => botConfig.botName.toLowerCase() === botName.toLocaleLowerCase())
      const timeout = botConfig?.allowedUploadTypes?.[fileExtension.slice(1)]?.timeout || botConfig?.defaultFileTimeout
      const token = await getToken()
      const response = await fetchData<ReadDocumentAPIResponse>({
        url: `${API_ENDPOINT}/uploadapi/${botName === 'KBOTS' ? 'readDocument' : 'readBotDocument'}`,
        token,
        payload: {
          botName: botName === 'KBOTS' ? undefined : botName,
          documentId,
          fileExtension,
          language: 'EN',
        },
        requestId: generateRequestId(),
        retries: 0,
        timeout,
      })

      if ('errorList' in response) {
        throw new UploadError(`upload-request-error-${response.errorList[0].code}`)
      }

      const { documentId: returnedDocumentId, text, characterCount, usedOCR } = response

      return {
        characterCount,
        returnedDocumentId,
        text,
        usedOCR,
      }
    } catch (e) {
      if (e instanceof UploadError) {
        throw e
      }

      throw new UploadError('upload-request-error-readDocument', e as Error)
    }
  }

  const uploadFileRequest = async (
    botName: string,
    file: File,
    fileConfigs: FileConfigs,
    saveConversations: boolean,
    uploadTokenMaxSize: number,
    kBotUploadedCharacters: number,
    conversationId?: string | null,
    append = false
  ) => {
    try {
      setIsFileUploading(true)

      const acceptableFile = await verifyAcceptableFile(file, fileConfigs)

      if (acceptableFile) {
        const textTypeFile = mapFileToText(acceptableFile, fileConfigs)
        const amendedFile = textTypeFile || acceptableFile
        // textTypeFile returns File when the file type matches what's in  MAP_UPLOAD_TYPES_TO_TEXT
        // if textTypeFile is undefined, get mime type by mime lib
        const contentType = textTypeFile?.type ?? getMimeType(amendedFile)

        const { documentId, url } = await getUploadUrl({ file: amendedFile, contentType }, botName)

        if (documentId && url) {
          await putFileIntoStorageContainer({
            append,
            botName,
            botNameForAPIToUse: botName,
            conversationId,
            documentId,
            file: acceptableFile,
            contentType,
            kBotUploadedCharacters,
            saveConversations,
            uploadTokenMaxSize,
            url,
          })
        }

        onClose()
      } else {
        throw new UploadError('unsupportedFileType')
      }
    } catch (e) {
      if (e instanceof NetworkError) {
        logUIErrorEvent({
          bot: botName,
          errorMessage: 'upload-file-request-error',
          requestId: e.requestId,
        })
      }

      if (e instanceof UploadSchemaError) {
        setUploadError(e.message)
      } else if (e instanceof UploadError) {
        if (e.message === 'upload-request-error-uploadUrl') {
          setUploadError('uploadUrlEndpointFailed')
        } else if (e.message === 'upload-request-error-documentUpload') {
          setUploadError('uploadError')
        } else if (e.message === 'upload-request-error-readDocument') {
          setUploadError('readDocumentEndpointFailed')
        } else if (e.message === 'upload-request-error-exceedsCharLimit') {
          setUploadError('exceedsCharacterLimit')
        } else if (e.message === 'upload-request-error-overTokenLimit') {
          setUploadError('overTokenLimit')
        } else if (e.message === 'upload-request-error-audio-429') {
          setUploadError('audio-429')
        } else if (e.message === 'upload-request-error-audio-400') {
          setUploadError('audio-400')
        } else if (e.message === 'unsupportedFileType') {
          setUploadError('unsupportedFileType')
        } else {
          setUploadError('uploadError')
        }
      } else if (e instanceof EmptyError) {
        setUploadError('emptyError')
      } else {
        // generic error
        setUploadError('uploadError')
      }
    } finally {
      setIsFileUploading(false)
    }
  }

  const deleteFileContext = useCallback(
    async (botName: string, convID: string): Promise<void> => {
      // safeguard to block double click
      if (isDeletingFileContext || isDeletingFileContextRef.current) {
        return
      }

      isDeletingFileContextRef.current = true
      setIsDeletingFileContext(true)

      try {
        const authToken = await getToken()
        const response = await fetchData<ConversationConfigUpdateResponse>({
          url: `${API_ENDPOINT}/chatapi/UpdateConvApi`,
          token: authToken,
          setRetrying: () => false,
          payload: {
            botName,
            convID,
            config: {
              fileContext: '',
            },
            language: 'EN',
          },
          requestId: generateRequestId(),
        })

        if ('errorList' in response) {
          console.error('Error occurred:', response.errorList[0].code)
        }
      } catch (error) {
        console.error('Error occurred when trying to update file context', error)
      } finally {
        // Reset safeguard
        isDeletingFileContextRef.current = false
        setIsDeletingFileContext(false)
      }
    },
    [API_ENDPOINT, getToken, isDeletingFileContext]
  )

  /**
   * Deletes an specific file's content
   * @param {string} conversationID - The ID of the conversation to be deleted.
   * @returns {void}
   */
  const deleteDocumentContent = useCallback(
    async (botNames: string[], conversationIDsToDelete: string[], saveConversation?: boolean): Promise<void> => {
      setDocContents((currentDocContents) => {
        const filteredDocContents: Record<string, Record<string, UploadDocContents>> = {}

        for (const botName of botNames) {
          if (botName in currentDocContents) {
            // Initialize filteredDocContents[botName] as an empty object if it doesn't exist
            filteredDocContents[botName] = {}

            for (const key in currentDocContents[botName]) {
              if (!conversationIDsToDelete.includes(key)) {
                // Now safely assign the value after initializing the botName object
                filteredDocContents[botName][key] = currentDocContents[botName][key]
              }
            }
          }
        }

        return { ...currentDocContents, ...filteredDocContents }
      })
      // If saveConversation is true, we know we are dealing with delete file content from the General Bot.
      // Therefore it will always be the case that there is only one botName and conversationID being passed in.
      if (saveConversation) {
        deleteFileContext(botNames[0], conversationIDsToDelete[0])
      }
    },
    [deleteFileContext]
  )

  /**
   * Deletes the content chosen bot(s)'s temporary conversation
   * @param {string[]} botNamesToDelete - The bot(s) to have doc contents deleted
   * @returns {void}
   */
  const deleteTempDocuments = useCallback((botNamesToDelete: string[]): void => {
    setTempDocContents((currentTempDocContents) => {
      const filteredDocContents: Record<string, UploadDocContents> = {}

      for (const botNameInCurrent in currentTempDocContents) {
        if (!botNamesToDelete.includes(botNameInCurrent)) {
          filteredDocContents[botNameInCurrent] = currentTempDocContents[botNameInCurrent]
        }
      }

      return { ...filteredDocContents }
    })
  }, [])

  const replaceConversationIdInDocContents = (payload: {
    botName: string
    oldConversationId: string | null
    newConversationId: string
    initializeIfNoBotRecordExists?: boolean
  }) => {
    const { botName, initializeIfNoBotRecordExists, oldConversationId, newConversationId } = payload
    replaceIdInRecordBotSpecific({
      botName,
      initializeIfNoBotRecordExists,
      oldConversationId,
      newConversationId,
      setState: setDocContents,
      oldValues: tempDocContents[botName],
    })
    // wipe the temp doc contents for this bot
    setTempDocContents((current) => {
      const newContents = { ...current }
      delete newContents[botName]
      return {
        ...newContents,
      }
    })
  }

  return (
    <>
      <UploadContext.Provider
        value={{
          deleteDocumentContent,
          deleteTempDocuments,
          docContents,
          getUploadFileParameters,
          isDeletingFileContext,
          isFileUploading,
          isWarningAcknowledged,
          replaceConversationIdInDocContents,
          setDocContents,
          setIsWarningAcknowledged,
          setUploadError,
          tempDocContents,
          uploadError,
          uploadFileRequest,
          uploadModalOperations: {
            isOpen,
            onOpen,
            onClose,
          },
        }}
      >
        {children}
      </UploadContext.Provider>
    </>
  )
}

export const useUploadContext = (): UploadContextType => useContext(UploadContext)
